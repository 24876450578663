// Web3OnboardContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { init, useConnectWallet } from '@web3-onboard/react';
import walletConnectModule from '@web3-onboard/walletconnect';
import metamaskModule from '@web3-onboard/metamask';
import injectedModule from '@web3-onboard/injected-wallets';
import { ethers } from 'ethers';
import { activeCurrentChainId } from '../ContractAction/ContractDependency';

const apiKey = '1730eff0-9d50-4382-a3fe-89f0d34a2070';
const injected = injectedModule();


let ethersProvider;
// const metamask = metamaskModule({
//     options: {
//         extensionOnly: true,  
//         requiredChains: [0x14a34],  
//         optionalChains: [1, 42161, 8453, 10, 137, 56],  
//         dappMetadata: {
//             name: 'Web3Onboard React Demo',  
//             url: 'https://ttavatar-test.xyz'  
//         },        i18nOptions: {
//             enabled: true 
//         }
//     }
//   });
  
//walletConnect
const walletConnect = walletConnectModule({
    projectId: '9754fe0ca527dd492a84d4ac073d1927',
    requiredChains: [0x2105],
    optionalChains: [1, 42161, 8453, 10, 137, 56],
    dappUrl: 'https://ttavatars.io/'
})
const web3Onboard = init({
    apiKey,
    wallets: [injected, walletConnect],
    chains: [
        {
            id: '0x38',
            token: 'BNB',
            label: 'Binance Smart Chain Mainnet',
            rpcUrl: 'https://bsc-dataseed.binance.org/'
        },
        {
            id: '0xa4b1',
            token: 'ARB-ETH',
            label: 'Arbitrum One',
            rpcUrl: 'https://rpc.ankr.com/arbitrum'
        },
        {
            id: '0xa4ba',
            token: 'ARB',
            label: 'Arbitrum Nova',
            rpcUrl: 'https://nova.arbitrum.io/rpc'
        },
        {
            id: '0x2105',
            token: 'ETH',
            label: 'Base',
            rpcUrl: 'https://base-mainnet.g.alchemy.com/v2/TL1C3CHMyFro3GMcrKnc_4nxi4y2E1dw'
        },
        {
            id: '0x14a33',
            token: 'ETH',
            label: 'Base Sepolia',
            rpcUrl: 'https://sepolia.base.org'
        },
        {
            id: 11155111,
            token: 'ETH',
            label: 'Sepolia',
            rpcUrl: 'https://rpc.sepolia.org/'
        }
    ],
    accountCenter: {
        desktop: {
            enabled: true,
            position: 'bottomRight'
        },
        mobile: {
            enabled: true,
            position: 'bottomRight'
        }
    }
  });

// Create the context
const Web3OnboardContext = createContext();

export const Web3OnboardProvider = ({ children }) => {
    const [provider, setProvider] = useState();
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    console.log(wallet, 'walletwallet');
    const [notiUpdated, setNotiUpdated] = useState(false);


    // useEffect(() => {
    //   async function setupProvider() {
    //     if (wallet) {
    //       try {
    //         ethersProvider = new ethers.providers.Web3Provider(wallet.provider);
    //         console.log("ethersProvider", ethersProvider);
    //         setProvider(ethersProvider);
    //       } catch (error) {
    //         console.error("Failed to setup provider:", error);
    //       }
    //     } else {
    //       setProvider(undefined);
    //     }
    //   }
    //   setupProvider();
    // }, [wallet]);
    // useEffect(() => {
    //   if (wallet)
    //     activeCurrentChainId();
    // }, [wallet])
    // useEffect(() => {
    //   async function stayConnect() {
    //     const addrs = localStorage.getItem('connectedAccount');
    //     if (addrs && !ethersProvider) {
    //       await connect();
    //     }

    //   }

    //   stayConnect()
    // }, [])

    useEffect(() => {
        async function setupAndStayConnected() {
            const addrs = localStorage.getItem('connectedAccount');
            // Step 1: Stay connected if there's a previously connected account
            if (addrs && !ethersProvider) {
                await connect();
            }
            // Step 2: Setup provider if wallet is connected
            if (wallet) {
                try {
                    ethersProvider = new ethers.providers.Web3Provider(wallet.provider);
                    console.log("ethersProvider", ethersProvider);
                    setProvider(ethersProvider);
                    // Step 3: Activate current chain ID
                    activeCurrentChainId();
                } catch (error) {
                    console.error("Failed to setup provider:", error);
                }
            } else {
                setProvider(undefined);
            }
        }

        setupAndStayConnected();
        
    }, [wallet, connect]);


    return ( <
        Web3OnboardContext.Provider value = {
            { web3Onboard, wallet, connecting, connect, disconnect, provider, setProvider, notiUpdated, setNotiUpdated }
        } > { children } </Web3OnboardContext.Provider>
    );
};

// Custom hook to use the Web3Onboard context
export const useWeb3Onboard = () => {
    return useContext(Web3OnboardContext);
};
export { ethersProvider };